(function ($) {

	var methods = {
		init: function () {
			return this.each(function () {

				// For each set of tabs, we want to keep track of
				// which tab is active and its associated content
				var $this = $(this),
				    window_width = $(window).width();

				$this.width('100%');
				// Set Tab Width for each tab
				var $num_tabs = $(this).children('li').length;
				$this.children('li').each(function () {
					$(this).width((100 / $num_tabs) + '%');
				});
				var $active, $content, $links = $this.find('li'),
				    $tabs_width = $this.width(),
				    $tab_width = $this.find('li').first().outerWidth(),
				    $index = 0;

				// If the location.hash matches one of the links, use that as the active tab.
				//$active = $($links.filter('[href="' + location.hash + '"]'));
				$active = $this.find('li.active');

				// If no match is found, use the first link or any with class 'active' as the initial active tab.
				if ($active.length === 0) {
					$active = $(this).find('li.active').first();
				}
				if ($active.length === 0) {
					$active = $(this).find('li').first();
				}

				$active.addClass('active');
				$index = $links.index($active);
				if ($index < 0) {
					$index = 0;
				}

				$content = $($active[0].hash);

				// append indicator then set indicator width to tab width
				$this.append('<div class="indicator"></div>');
				var $indicator = $this.find('.indicator');
				if ($this.is(":visible")) {
					$indicator.css({"right": $tabs_width - (($index + 1) * $tab_width)});
					$indicator.css({"left": $index * $tab_width});
				}
				$(window).resize(function () {
					$tabs_width = $this.width();
					$tab_width = $this.find('li').first().outerWidth();
					if ($index < 0) {
						$index = 0;
					}
					if ($tab_width !== 0 && $tabs_width !== 0) {
						$indicator.css({"right": $tabs_width - (($index + 1) * $tab_width)});
						$indicator.css({"left": $index * $tab_width});
					}
				});

				// Hide the remaining content
				$links.not($active).each(function () {
					$(this.hash).hide();
				});


				// Bind the click event handler
				$this.on('click', 'a, li', function (e) {
					$tabs_width = $this.width();
					$tab_width = $this.find('li').first().outerWidth();

					// Make the old tab inactive.
					//$active.removeClass('active');
					//$content.hide();

					// Update the variables with the new link and content
					$active = $(this);
					$content = $(this.hash);
					$links = $this.find('li');

					// Make the tab active.
					//$active.addClass('active');
					var $prev_index = $index;
					$index = $links.index($(this));
					console.log($index);
					console.log($prev_index);
					if ($index < 0) {
						$index = 0;
					}
					// Change url to current tab
					// window.location.hash = $active.attr('href');

					//$content.show();

					// Update indicator
					if (($index - $prev_index) >= 0) {
						$indicator.velocity({
							"right": $tabs_width - (($index + 1) * $tab_width)}, {
							duration: 300,
							queue: false,
							easing: 'easeOutQuad'
						});
						$indicator.velocity({"left": $index * $tab_width}, {
							duration: 300,
							queue: false,
							easing: 'easeOutQuad',
							delay: 90
						});

					}
					else {
						$indicator.velocity({"left": $index * $tab_width}, {
							duration: 300,
							queue: false,
							easing: 'easeOutQuad'
						});
						$indicator.velocity({"right": $tabs_width - (($index + 1) * $tab_width)}, {
							duration: 300,
							queue: false,
							easing: 'easeOutQuad',
							delay: 90
						});
					}

					// Prevent the anchor's default click action
					e.preventDefault();
				});
			});

		},
		select_tab: function (id) {
			this.find('a[href="#' + id + '"]').trigger('click');
		}
	};

	$.fn.xtabs = function (methodOrOptions) {
		if (methods[methodOrOptions]) {
			return methods[methodOrOptions].apply(this, Array.prototype.slice.call(arguments, 1));
		} else if (typeof methodOrOptions === 'object' || !methodOrOptions) {
			// Default to "init"
			return methods.init.apply(this, arguments);
		} else {
			$.error('Method ' + methodOrOptions + ' does not exist on jQuery.tooltip');
		}
	};

	$(document).ready(function () {
		//$('ul.nav-tabs').xtabs();
	});
}(jQuery));
